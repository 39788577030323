import mainImg from './main.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="container">
              <div className="text_logo">Simpleslife.net</div>
          </div>
          <div>
              <a href="https://www.sofiadate.com/lnd37/block1?utm_netw=il_return">
                  <img src={mainImg} className="App-logo" alt="main" />
              </a>
              <a className="btn__wrapper" href="https://www.sofiadate.com/lnd20/block1?utm_netw=il_tiktok">
                  <div className="main_btn"><span>👉</span> Click Here <span>👈</span></div>
              </a>
          </div>
      </header>
    </div>
  );
}

export default App;
